
export const default_state = {
    isLoading: false,
    test: "You have accessed the test: property."
}

export const default_menu_links = [
    "home",
    "about",
    "portfolio",
    "contact",
]