import './style.scss'
import DarkModeButton from '../DarkModeButton'

export default function Footer(){
    return (
        <footer className="site-footer">
            <div className="content-container">
                <div className="navbar">
                    <DarkModeButton />

                    <div className="social-links-container">
                        <ul className="social-links">
                            <li className="social-link-item">
                                <a className="social-link site-link" href="https://twitter.com/j8ahmed">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>

                            <li className="social-link-item">
                                <a className="social-link site-link" href="https://www.linkedin.com/in/j8ahmed/">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </li>

                            <li className="social-link-item">
                                <a className="social-link site-link" href="https://www.youtube.com/channel/UCam3QW0KOgIbWtlfFz2z83w?view_as=subscriber">
                                    <i className="fab fa-youtube"></i>  
                                </a>
                            </li>

                            <li className="social-link-item">
                                <a className="social-link site-link" href="https://github.com/j8ahmed">
                                    <i className="fab fa-github"></i>  
                                </a>
                            </li>

                            <li className="social-link-item">
                                <a className="social-link site-link" href="https://codepen.io/j8ahmed">
                                    <i className="fab fa-codepen"></i>  
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="copyright">Copyright &copy; {new Date().getFullYear()} Jamal J8 Ahmed. All rights reserved.</div>
            </div>
        </footer>
    )
}
