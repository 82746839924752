import { createSlice } from '@reduxjs/toolkit'


//selectors
export const siteTheme = state => state.global.theme

//initial state
const initialState = {
    theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'darkTheme' : 'lightTheme'
}
//utility functions

//slice object
const globalSlice = createSlice({
    name: "global",
    initialState: initialState,
    reducers:{
        toggleTheme(state){
            state.theme = state.theme === 'lightTheme' ? 'darkTheme' : 'lightTheme'
        },
        setTheme(state, action){
            state.theme = action.payload
        }
    }
})


export const { toggleTheme, setTheme } = globalSlice.actions

export default globalSlice.reducer

