import './style.scss'
import gsap from 'gsap'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import DarkModeButton from '../../../DarkModeButton'
import Logo from "./assets/logo-icon.png"

export default function MobileMenu({menuLinks, showMenu}){
    const [animTimeline, setAnimTimeline] = useState(null);

    const closeMenu = () => {
        // run menu close animation before sending signal to parent component to unmount mobile menu
        animTimeline
            .fromTo(".mobile-menu-container", {left: "0"}, {left: "100%", duration: 0.25})
            .to(".mobile-menu-container", {visibility:"hidden", duration: 0})
            .add(() => {
                showMenu(false)
            })
    }

    useEffect(() => {
        // Start animations for Mobile Menu
        const gsapContext = gsap.context(() => {
            const timeline = gsap.timeline({autoAlpha:0});

            // Run initial page load animation
            timeline
                .to(".mobile-menu-container", {visibility:"visible", duration: 0})
                .fromTo(".mobile-menu-container", {left: "100%"}, {left: "0"})
            setAnimTimeline(timeline)
        })

        return () => {
            gsapContext.revert();
        }
    }, [])


    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu">
                <div className="header">
                    <div className="logo-container">
                        <img src={Logo} alt="J8ahmed logo" onLoad={(e) => e.target.style.opacity = "1"}/>
                    </div>

                    <div className="mobile-menu-btn-container">
                        <button className="mobile-menu-btn" onClick={closeMenu}>
                            <div className="btn-bar close-btn-bar"></div>
                            <div className="btn-bar close-btn-bar"></div>
                            <div className="btn-bar close-btn-bar"></div>
                        </button>
                    </div>
                </div>

                <nav className="links-container">
                    <ul className="links-list">
                        {menuLinks.map( (link, i) => {
                            return(
                                <li key={i} className="link-item">
                                    <NavLink className="nav-link" 
                                        activeClassName="active" 
                                        onClick={closeMenu}
                                        exact to={link === "home" ? "/" : `/${link}`}
                                    >
                                        {link}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>

                    <div className="darkmode-toggle-container">
                        <DarkModeButton/>
                    </div>
                </nav>
            </div>
        </div>
    )
}
