import './style.scss'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { default_menu_links } from '../../../assets/defaults'
import { MobileMenu } from './components/'
import Logo from "./assets/logo-icon.png"

export default function Header(){
    const [ showLinks, setShowLinks ] = useState(false)

    return (
        <>
        <header className="site-header">
            <div className="navbar-container">
                <div className="navbar">
                    <div className="logo-container">
                        <img src={Logo} alt="J8ahmed logo" onLoad={(e) => e.target.style.opacity = "1"}/>
                    </div>
                    <nav className={`links-container`}>
                        <ul className="links-list">
                            {default_menu_links.map( (link, i) => {
                                return(
                                    <li key={i} className="link-item">
                                        <NavLink className="nav-link" 
                                            activeClassName="active" 
                                            exact to={link === "home" ? "/" : `/${link}`}>
                                        {link}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <div className="mobile-menu-btn-container">
                        <button className="mobile-menu-btn" onClick={() => setShowLinks(showLinks => !showLinks)}>
                            <div className="btn-bar"></div>
                            <div className="btn-bar"></div>
                            <div className="btn-bar"></div>
                        </button>
                    </div>
                </div>
            </div>
        </header>

        {showLinks ? <MobileMenu showMenu={setShowLinks} menuLinks={default_menu_links} /> : ""}
        </>
    )
}
