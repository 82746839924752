import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import Loader from '../Loader'

/* Lazy Load Pages */
const HomePage = lazy(() => import("../../../pages/HomePage"));
const AboutPage = lazy(() => import("../../../pages/AboutPage"));
const PortfolioPage = lazy(() => import("../../../pages/PortfolioPage"));
const ProjectPage = lazy(() => import("../../../pages/ProjectPage"));
const ContactPage = lazy(() => import("../../../pages/ContactPage"));
const ErrorPage = lazy(() => import("../../../pages/ErrorPage"));
// const BlogPage = lazy(() => import("../../../pages/BlogPage"));
// const BlogPostPage = lazy(() => import("../../../pages/BlogPostPage"));


export default function ReactRouterSetup(){
    const pageClass = "page-container"
    const pageClassSelector = `.${pageClass}`

    return (
        <Router>
            <Header/>

            <main className={pageClass}>
                <Switch>
                    <Route exact path="/">
                        <Suspense fallback={<Loader/>}>
                            <HomePage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                    <Route exact path="/about">
                        <Suspense fallback={<Loader/>}>
                            <AboutPage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                    <Route exact path="/portfolio">
                        <Suspense fallback={<Loader/>}>
                            <PortfolioPage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                    <Route exact path="/portfolio/project:id">
                        <Suspense fallback={<Loader/>}>
                            <ProjectPage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                    {/*
                        <Route exact path="/blog">
                            <Suspense fallback={<Loader/>}>
                                <BlogPage pageContainer={pageClassSelector}/>
                            </Suspense>
                        </Route>
                        <Route exact path="/blog/post:id">
                            <Suspense fallback={<Loader/>}>
                                <BlogPostPage pageContainer={pageClassSelector}/>
                            </Suspense>
                        </Route>
                    */}
                    <Route exact path="/contact">
                        <Suspense fallback={<Loader/>}>
                            <ContactPage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                    <Route path="*">
                        <Suspense fallback={<Loader/>}>
                            <ErrorPage pageContainer={pageClassSelector}/>
                        </Suspense>
                    </Route>
                </Switch>
            </main>

            <Footer />
        </Router>
    )
}
