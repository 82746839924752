import "./scss/main.scss"
import { Provider } from 'react-redux'
import store from './assets/redux/store'
import ReactRouterSetup from './components/global/ReactRouter'

export default function App(){
    return (
        <Provider store={store}>
            <ReactRouterSetup/>
        </Provider>
    )
}
